// import pages from './pages';
import utilities from './utilities';
// import other from './other';
import pastetext from './pastetext';
// ==============================|| MENU ITEMS ||============================== //
//items: [dashboard, pages, utilities, other]
const menuItems = {
  items: [ pastetext, utilities]
};

export default menuItems;
