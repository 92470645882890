import * as React from 'react';
import { TextField, Grid, IconButton, Box, CircularProgress } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import axios from '../Axios/axiosConfig';

export default function Captcha({ setIsCaptchaValid }) {
  // Error
  const [error, setError] = React.useState('');
  // Captcha
  const [captchaImage, setCaptchaImage] = React.useState('');
  const [captchaText, setCaptchaText] = React.useState('');
  const [responseCaptchaText, setResponseCaptchaText] = React.useState('');
  const [loading, setLoading] = React.useState(true); // Initialize as loading

  const fetchCaptcha = async () => {
    try {
      const response = await axios.get('/texts/text/captcha'); // Replace with your server URL
      setCaptchaImage(response.data.captchaData);
      setResponseCaptchaText(response.data.captchaText);
      setLoading(false); // Set loading to false when data is received
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    fetchCaptcha();
  }, []);

  const handleOnChange = (event) => {
    setCaptchaText(event.target.value);
    if (event.target.value !== responseCaptchaText) {
      setIsCaptchaValid(false);
      setError('Invalid captcha');
    } else {
      setError('');
      setIsCaptchaValid(true);
    }
  };

  return (
    <Grid container spacing={1} sx={{ p: 2 }} justifyContent="center" alignItems="center">
      <Grid item xs={6} sm={6}>
        <TextField
          fullWidth
          label="Enter image text"
          margin="normal"
          name="code"
          value={captchaText}
          type="text"
          size="small"
          onChange={handleOnChange}
          error={error !== ''}
          helperText={error}
          autoComplete="off" 
        />
      </Grid>
      <Grid item xs={1} sm={1}>
        <Box>
          <IconButton size="small" aria-label="close" color="inherit" onClick={fetchCaptcha}>
            <CachedIcon fontSize="small" />
          </IconButton>
        </Box>
      </Grid>
      <Grid item xs={5} sm={5}>
        {loading ? ( // Check if loading, show loading spinner
          <CircularProgress color="secondary" />
        ) : (
          responseCaptchaText !== '' && ( // Check if data received
            <img
              src={`data:image/svg+xml;base64,${btoa(captchaImage)}`}
              alt="Copy Paste CAPTCHA"
              title="CAPTCHA - Just Paste It"
              loading="lazy"
            />
          )
        )}
      </Grid>
    </Grid>
  );
}
