import React, { useState, useEffect } from "react";
import { Typography, Grid, TextField, Button, Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MainCard from "../components//cards/MainCard";
import axios from "../Axios/axiosConfig";
import CustomSnackbar from "../components//snackbar/CustomSnackbar";
import { useLocation } from "react-router-dom";
const ContactUs = () => {
  const location = useLocation();
  const classes = useTheme();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const item = urlParams.get("item");
    if (item === "canvapro") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        message:
          "Get the Canva Pro link in DM, please fill out the email correctly and our team will reach out to you.",
      }));
    }
  }, [location.search]);

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) {
      newErrors.name = "Name is required";
    }
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = "Invalid email address";
    }
    if (!formData.message) {
      newErrors.message = "Message is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Handle form submission here, e.g., send data to the server
      await axios.post("/contact/add", formData);
      setShowSnackbar(true);
      setSnackbarType("contactSuccess");
      // Clear the form after submission
      setFormData({
        name: "",
        email: "",
        message: "",
      });
    }
  };
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [snackbarType, setSnackbarType] = React.useState("");
  return (
    <MainCard
      title=""
      border={false}
      elevation={1}
      content={true}
      boxShadow
      shadow={classes.shadows[16]}
    >
      <Typography
        variant="h1"
        gutterBottom
        style={{ fontSize: "1.125rem", fontWeight: 500 }}
      >
        Contact Us
      </Typography>
      <Divider />
      <CustomSnackbar showSnackbar={showSnackbar} snackbarType={snackbarType} />
      <Typography variant="body1" paragraph></Typography>
      <Typography variant="body1" paragraph>
        If you have any questions, comments or suggestions we would love to hear
        from you and will get back to you as soon as possible.
      </Typography>
      <Typography variant="body1" paragraph></Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Name"
              variant="outlined"
              name="name"
              value={formData.name}
              onChange={handleChange}
              error={!!errors.name}
              helperText={errors.name}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              name="email"
              value={formData.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Message"
              multiline
              rows={4}
              variant="outlined"
              name="message"
              value={formData.message}
              onChange={handleChange}
              error={!!errors.message}
              helperText={errors.message}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="secondary">
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </MainCard>
  );
};

export default ContactUs;
