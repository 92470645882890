import React from "react";
// import { lazy } from 'react';

// project imports
import MainLayout from '../components/mainLayout/MainLayout';
// import Loadable from '../components/Loadable';

// dashboard routing
// const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
// const PasteText = Loadable(lazy(() => import('views/utilities/PasteText')));

// Inner page routing
// const About = Loadable(lazy(() => import('views/pages/authentication/About')));
// const PrivacyPolicy = Loadable(lazy(() => import('views/pages/authentication/PrivacyPolicy')));
// const Terms = Loadable(lazy(() => import('views/pages/authentication/Terms')));
// const ContactUs = Loadable(lazy(() => import('views/pages/authentication/ContactUs')));
// const MainBlog =  Loadable(lazy(() => import('views/pages/authentication/MainBlog')));
import Blog from "../pages/blogs/Blog";
import About from "../pages/About";
import Terms from "../pages/Terms";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import ContactUs from "../pages/ContactUs";
import PasteText from "../components/PasteText";
import PublishText from "../components/publishText";
import LinkPage from "../pages/LinkPage";
import DisclaimerPage from "../pages/Disclaimer";
import ShippingAndDeliveryPolicy from "../pages/ShippingAndDeliveryPolicy";
import CancellationAndRefundPolicy from "../pages/CancellationAndRefundPolicy";
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <PasteText />
    },
    {
      path: '/publish',
      element: <PublishText />
    },
    {
      path: 'about-us',
      element: <About />
    },
    {
      path: 'blog/react-ssr-seo-react-helmet',
      element: <Blog />
    },
    {
      path: 'blog/tailwindcss',
      element: <Blog />
    },
    {
      path: 'blog/godaddy-hosting-web',
      element: <Blog />
    },
    {
      path: '/terms',
      element: <Terms />
    },
    {
      path: '/privacypolicy',
      element: <PrivacyPolicy />
    },
    {
      path: '/disclaimer',
      element: <DisclaimerPage />
    },
    {
      path: '/contact-us',
      element: <ContactUs />
    },
    {
      path: '/shop',
      element: <LinkPage />
    },
    {
      path: '/delivery',
      element: <ShippingAndDeliveryPolicy />
    },
    {
      path: '/refund',
      element: <CancellationAndRefundPolicy />
    }
  ]
};

export default MainRoutes;
