import React from "react";
import MainCard from "../components/cards/MainCard";
import {
  Typography,
  Divider,
  Link,
} from "@mui/material";

class DisclaimerPage extends React.PureComponent {
  render() {
    return (
      <MainCard title="" border={false} elevation={1} content={true} boxShadow>
        <Typography
          variant="h1"
          gutterBottom
          style={{ fontSize: "1.125rem", fontWeight: 500 }}
        >
          Disclaimer - Just Paste It
        </Typography>
        <Divider />
        <Typography variant="body1" paragraph></Typography>
        <Typography variant="body1" paragraph>
          Welcome to Just Paste It, a platform designed to help you share text,
          images, and files across multiple devices using unique codes. Before
          using our services, please read this disclaimer carefully.
        </Typography>
        <Typography variant="body1" paragraph>
          Just Paste It provides a convenient way to share content, but we are
          not responsible for the content you share or access through our
          platform. Users are solely responsible for the content they upload,
          paste, or share.
        </Typography>
        <Typography variant="body1" paragraph>
          We do not guarantee the accuracy, completeness, or reliability of the
          content shared on Just Paste It. Users should exercise caution and
          discretion when accessing or sharing content.
        </Typography>
        <Typography variant="body1" paragraph>
          By using Just Paste It, you agree to comply with our terms of service
          and privacy policy. We reserve the right to suspend or terminate
          accounts that violate our policies.
        </Typography>
        <Typography variant="body1" paragraph>
          From our website, you can visit other websites by following hyperlinks
          to such external sites. While we strive to provide only quality links
          to useful and ethical websites, we have no control over the content
          and nature of these sites. These links to other websites do not imply
          a recommendation for all the content found on these sites. Site owners
          and content may change without notice and may occur before we have the
          opportunity to remove a link which may have gone bad. Please be also
          aware that when you leave our website, other sites may have different
          privacy policies and terms which are beyond our control. Please be
          sure to check the Privacy Policies of these sites as well as their
          “Terms of Service” before engaging in any business or uploading any
          information.
        </Typography>
        <Typography variant="body1" paragraph>
          Consent - By using our website, you hereby consent to our disclaimer
          and agree to its terms.
        </Typography>
        <Typography variant="body1" paragraph>
          Affiliate Disclosure - Just Paste it is a participant in the Amazon
          Services LLC Associates Program, an affiliate advertising program
          designed to provide a means for sites to earn advertising fees by
          advertising and linking to Amazon.com and affiliated sites. As an
          Amazon Associate, we earn from qualifying purchases.
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions or concerns about our disclaimer or terms of
          service, please contact us at{" "}
          <Link href="mailto:support@justpasteit.in">
            support@justpasteit.in
          </Link>
          .
        </Typography>
      </MainCard>
    );
  }
}

export default DisclaimerPage;
