import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function CustomSnackbar({ showSnackbar, snackbarType }) {
  const [open, setOpen] = React.useState(showSnackbar);
  // const [snackMessage,setSnackMessage] = React.useState(snackbarType)
  const snackBarText = {
    fetchSuccess: 'Message loaded successfully',
    postSuccess: 'Message submitted successfully, You can get the text now',
    failed: 'Something went wrong please try again',
    contactSuccess: "Thank you! Your message has been sent successfully"
  };
  // const handleClick = () => {
  //   setOpen(true);
  // };
  React.useEffect(() => {
    setOpen(showSnackbar);
  }, [showSnackbar]);

  // React.useEffect(() => {
  //   setSnackMessage(snackbarType);
  // }, [snackbarType]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const action = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={snackBarText[snackbarType] ? snackBarText[snackbarType] : ''}
        action={action}
      >
        <Alert
          onClose={handleClose}
          severity={snackbarType === 'contactSuccess' || snackbarType === 'fetchSuccess' || snackbarType === 'postSuccess' ? 'success' : 'error'}
        >
          {snackBarText[snackbarType] ? snackBarText[snackbarType] : ''}
        </Alert>
      </Snackbar>
    </div>
  );
}
