import React from "react";
// material-ui
import {
  Typography,
  Paper,
  Button,
  Divider,
  IconButton,
  Tooltip,
} from "@mui/material";
import MainCard from "../../components/cards/MainCard";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReactssrImg from "../../assets/images/blog/Reactssr1.webp";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";

class ReactSSR extends React.PureComponent {

  handleDownloadFile = (filename) => {
    // Replace 'http://localhost:8994' with your actual server URL
    const fileURL = `https://justpasteit.in/${filename}`;

    // Fetch the file and trigger the download
    fetch(fileURL)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = filename.split('-')[1] || filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Error downloading file:', error);
      });
  }

  render() {
    return (
      <MainCard
        title=""
        border={false}
        elevation={1}
        content={true}
      >
        <Typography
          variant="h1"
          gutterBottom
          style={{ fontSize: "1.125rem", fontWeight: 500 }}
        >
          Reactjs Server Side Rendering (SSR) and SEO with React Helmet
        </Typography>
        <img
          src={ReactssrImg}
          alt="react ssr"
          style={{ width: "100%", height: "50%" }}
        />

        <Divider />
        <Typography variant="body1" paragraph></Typography>
        <Paper>
          <Typography variant="body2" margin={1} gutterBottom>
            In today&apos;s digital age, ensuring that the web application ranks
            high on Google search results is crucial for its success. In this
            blog post, let us understand how to implement Search Engine
            Optimization (SEO) in a React application to achieve top Google
            search rankings organically. We&apos;ll also address some common
            concerns and provide a solution that can significantly improve your
            application&apos;s performance.
          </Typography>
          <Typography
            variant="h2"
            color="secondary"
            margin={1}
            sx={{
              fontSize: "1rem",
            }}
          >
            Following Concerns about SEO in Single Page Applications (SPAs)
          </Typography>
          <Typography
            variant="h2"
            color="secondary"
            margin={1}
            sx={{
              fontSize: "1rem",
              display: "flex", // Center content vertically
              alignItems: "center", // Center content vertically
            }}
          >
            <CheckCircleIcon
              color="secondary"
              sx={{ marginRight: 1, fontSize: "18px" }}
            />{" "}
            {/* Adjust margin-right as needed */}
            Can Google {"crawl"} SPA pages?
          </Typography>
          <Typography variant="body2" margin={1} gutterBottom>
            The short answer is no. Google&apos;s web crawlers can only crawl
            the index page of a SPA, which means that the content of dynamically
            loaded pages may not be indexed correctly.
          </Typography>
          <Typography
            variant="h2"
            color="secondary"
            margin={1}
            sx={{
              fontSize: "1rem",
              display: "flex", // Center content vertically
              alignItems: "center", // Center content vertically
            }}
          >
            <CheckCircleIcon
              color="secondary"
              sx={{ marginRight: 1, fontSize: "18px" }}
            />{" "}
            {/* Adjust margin-right as needed */}
            Can meta tags be set dynamically?
          </Typography>
          <Typography variant="body2" margin={1} gutterBottom>
            Again, the answer is no. With traditional SPAs, setting dynamic meta
            tags for individual pages can be challenging, as the HTML page
            remains static.
          </Typography>
          <Typography
            variant="h2"
            color="secondary"
            margin={1}
            sx={{
              fontSize: "1rem",
              display: "flex", // Center content vertically
              alignItems: "center", // Center content vertically
            }}
          >
            <CheckCircleIcon
              color="secondary"
              sx={{ marginRight: 1, fontSize: "18px" }}
            />{" "}
            {/* Adjust margin-right as needed */}
            Is your application running slowly in the client&apos;s browser?
          </Typography>
          <Typography variant="body2" margin={1} gutterBottom>
            If the answer is yes, implementing React Server-Side Rendering (SSR)
            can significantly improve the application&apos;s performance..
          </Typography>
          <Typography
            variant="h2"
            color="secondary"
            margin={1}
            sx={{
              fontSize: "1rem",
            }}
          >
            React Server-Side Rendering (SSR) as the Solution
          </Typography>
          <Typography variant="body2" margin={1} gutterBottom>
            React Server-Side Rendering (SSR) is the solution to to these
            above-mentioned SEO concerns. SSR involves rendering your React
            components on the server and sending fully rendered HTML to the
            client, rather than relying on JavaScript to render content in the
            browser.
          </Typography>
          <Typography variant="body2" margin={1} gutterBottom>
            Now, let&apos;s dive into the implementation of React SSR.
          </Typography>
          <Typography
            variant="h2"
            color="secondary"
            margin={1}
            sx={{
              fontSize: "1rem",
            }}
          >
            Implementation of React SSR:
          </Typography>
          <Typography variant="body2" margin={1} gutterBottom>
            1. <strong>Folder Structure:</strong> To get started with React SSR,
            one should have a specific folder structure. And the project should
            include the following elements:
          </Typography>
          <Typography
            variant="body2"
            margin={1}
            gutterBottom
            sx={{ paddingLeft: "1rem" }}
          >
            a. <strong>Server Folder:</strong> This folder contains the server
            side code
          </Typography>
          <Typography
            variant="body2"
            margin={1}
            gutterBottom
            sx={{ paddingLeft: "1rem" }}
          >
            b. <strong>Client Folder:</strong> Inside this folder, one should
            have the complete React application.
          </Typography>
          <Typography variant="body2" margin={1} gutterBottom>
            2. <strong>Setting Up React Helmet:</strong>React Helmet is a
            library that allows you to set dynamic metadata for the React
            components or pages. You can use it to manage titles, descriptions,
            and other meta tags. For example, in an inner page like &quot;About
            Us&quot; you can use React Helmet to set metadata dynamically.
          </Typography>
          <Typography
            variant="h2"
            color="secondary"
            margin={1}
            sx={{
              fontSize: "1rem",
            }}
          >
            Code Overview
          </Typography>
          <Typography variant="body2" margin={1} gutterBottom>
            Here&apos;s an overview of the key steps involved in implementing
            React SSR:
          </Typography>
          <Typography variant="body2" margin={1} gutterBottom>
            1. <strong>Server Code:</strong> Set up your Node.js server,
            including routes, MongoDB connections, and static file serving from
            the {"Client"} folder.
          </Typography>
          <Typography variant="body2" margin={1} gutterBottom>
            2. <strong>Generate Build:</strong> Generate a build for your client
            side React application. This build will create a build folder inside
            the Client directory.
          </Typography>
          <Typography variant="body2" margin={1} gutterBottom>
            3. <strong>Server Side Rendering:</strong> Modify your server code
            to handle unknown routes by serving the HTML index file. This index
            HTML file will be manipulated to include dynamic meta tags and
            titles based on the requested URL.
          </Typography>
          <Typography variant="body2" margin={1} gutterBottom>
            4. <strong>Request Indexing:</strong> After implementing React SSR,
            request indexing of your inner pages through Google Search Console.
            This step ensures that Google indexes all the pages correctly.
          </Typography>
          <Typography
            variant="h2"
            color="secondary"
            margin={1}
            sx={{
              fontSize: "1rem",
            }}
          >
            Server.js File Example:{" "}
            <Button
              disableElevation
              size="small"
              type="submit"
              variant="contained"
              color="secondary"
              onClick={() => this.handleDownloadFile("serverExample")}
            >
              Download File
            </Button>
          </Typography>

          <Typography
            variant="h2"
            color="secondary"
            margin={1}
            sx={{
              fontSize: "1rem",
            }}
          >
            Deployment
          </Typography>
          <Typography variant="body2" margin={1} gutterBottom>
            To deploy the application, we need to deploy the server folder along
            with the entire folder structure. In the Client folder, deploy the
            build folder, which contains the client side React application.
            There&quot;s no need to include the node_modules or package.json
            files in the client side deployment.
          </Typography>
          <Typography
            variant="h2"
            color="secondary"
            margin={1}
            sx={{
              fontSize: "1rem",
            }}
          >
            Request Indexing
          </Typography>
          <Typography variant="body2" margin={1} gutterBottom>
            Finally, after deploying the application with React SSR, it&apos;s
            essential to request indexing for the inner pages through Google
            Search Console. This ensures that Google indexes all your pages
            correctly and improves their visibility in search results.
          </Typography>
          <Typography
            variant="h2"
            color="secondary"
            margin={1}
            sx={{
              fontSize: "1rem",
            }}
          >
            Conclusion
          </Typography>
          <Typography variant="body2" margin={1} gutterBottom>
            In this blog post, we explored the importance of SEO for React
            applications and the challenges faced by SPAs. We introduced React
            Server Side Rendering (SSR) as a powerful solution to these
            challenges. By implementing SSR, one can improve SEO, enhance user
            experience, and achieve top Google search rankings organically.
            Remember to follow the folder structure and steps outlined in this
            post for a successful implementation of React SSR. With these
            strategies in place, your web application will be well-optimized for
            search engines, providing better visibility and driving organic
            traffic to your site. Thank you for reading, and we hope you found
            this guide helpful for implementing SEO in your React application
            with React SSR.
          </Typography>
          <Typography variant="h4" margin={1} gutterBottom>
            You can watch the video version of this tutorial on{" "}
            <Tooltip title="Watch on YouTube" arrow>
              <IconButton
                href="https://youtu.be/a6ileLlq_gQ"
                target="_blank"
                rel="noopener noreferrer"
              >
                <PlayCircleOutlineIcon color="error" fontSize="large" />
              </IconButton>
            </Tooltip>
          </Typography>
        </Paper>
      </MainCard>
    );
  }
}

export default ReactSSR;
