import * as React from 'react';
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TextField, Grid, Button } from '@mui/material';
import Captcha from './Captcha';
import { useSelector } from "react-redux";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4
};
// material-ui
// import { useTheme } from '@mui/material/styles';

export default function GetTextModal({ isOpen, toggleGetTextModel, fetchData, error }) {
  const customization = useSelector((state) => state.customization);
  // const theme = useTheme();
  //captcha
  const [isCaptchaValid, setIsCaptchaValid] = React.useState(false);
  const [codeText, setCodeText] = React.useState('');
  const [textModelError, setTextModelError] = React.useState('');
  // const handleOpen = () => setOpen(isOpen || true);
  const handleTypeCode = (event) => {
    setCodeText(event.target.value);
  };

  const handleEnterKey = (event) => {
    if (event.key === 'Enter') {
      // Trigger button click when Enter key is pressed
      getMsgByCode();
    }
  };

  const getMsgByCode = () => {
    if ((isCaptchaValid && codeText.length !== 0) || (customization.user.name !== "" && codeText.length !== 0)) {
      fetchData(codeText);
      setTextModelError('')
    } else {
      setTextModelError('Invalid Captcha or Empty Get Text');
    }
  };

  return (
    <div>
      <Modal open={isOpen} onClose={toggleGetTextModel}>
        <Box sx={style} onKeyDown={handleEnterKey}>
          <Typography variant="h3">Get Text</Typography>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={23}>
              <TextField
                fullWidth
                label="Type Code"
                margin="normal"
                name="typecode"
                type="text"
                size="small"
                color="secondary"
                onChange={handleTypeCode}
                error={error !== ''}
                helperText={error}
                autoComplete="off"
              />
            </Grid>
            {(customization && customization.user.name === "" && <Captcha setIsCaptchaValid={setIsCaptchaValid} />)}
            <Typography variant="body2" color="error">
              {textModelError ? textModelError : ''}
            </Typography>
            <Grid item xs={12} sm={6}>
                <Button disableElevation fullWidth size="small" type="submit" variant="contained" color="secondary" onClick={getMsgByCode}>
                  Get Text
                </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
