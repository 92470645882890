import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Paper,
  Divider,
  Grid,
  Box,
  IconButton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MainCard from "../components/cards/MainCard";
import howItworks from "../assets/images/howItworks.jpg";
import { gridSpacing } from "../store/constant";
// import YouTube from 'react-youtube';

const About = () => {
  const classes = useTheme();
  const [text, setText] = useState("");
  const originalText =
    "Just Paste It - Super fast and Super Simple notetaking.";
  const typingSpeed = 100; // Adjust the typing speed here (in milliseconds)
  const cursorRef = useRef(null);

  useEffect(() => {
    let currentIndex = 0;
    let isDeleting = false;

    const typeText = () => {
      const currentChar = originalText[currentIndex];
      setText((prevText) =>
        isDeleting ? prevText.slice(0, -1) : prevText + currentChar
      );

      if (!isDeleting && currentIndex === originalText.length) {
        isDeleting = true;
      }

      if (isDeleting && currentIndex === 0) {
        isDeleting = false;
      }

      const typingInterval = isDeleting ? typingSpeed / 2 : typingSpeed;

      setTimeout(
        () => {
          typeText();
        },
        isDeleting && currentIndex === 0 ? 1500 : typingInterval
      );

      if (!isDeleting) {
        currentIndex++;
      } else {
        currentIndex--;
      }
    };
    typeText();
  }, []);

  return (
    <MainCard
      title=""
      border={false}
      elevation={1}
      content={true}
      boxShadow
      shadow={classes.shadows[16]}
    >
      <Typography
        variant="h1"
        gutterBottom
        style={{ fontSize: "1.125rem", fontWeight: 500 }}
      >
        About
      </Typography>
      <Divider />
      <Typography variant="body1" paragraph></Typography>
      <Paper>
        <Typography variant="h1" gutterBottom color={classes.palette.grey[500]}>
          {text}
          <span className={classes.cursor} ref={cursorRef}>
            |
          </span>
        </Typography>
        <Typography
          variant="h2"
          color="secondary"
          sx={{
            mt: 1,
            fontSize: "1rem",
          }}
        >
          Why choose Just Paste It?
        </Typography>
        <Grid container spacing={gridSpacing} justifyContent="space-between">
          <Grid item xs={12} md={8}>
            <Typography variant="body2" gutterBottom>
              At <strong>Just Paste It</strong>, we make things super easy! You
              can write and share text without needing to sign up for an account
              - it&apos;s that simple. Plus, we have cool tools to make your
              text look awesome. Here&apos;s the best part - we don&apos;t have
              annoying ads anywhere on our website. So, you can stay focused on
              what you&apos;re doing. We also care a lot about keeping your
              stuff safe and private. Your words are like a secret diary here.
              We don&apos;t share or sell your stuff to anyone. Your trust is
              super important to us. So, if you want a place to write and share
              without any ads and with extra privacy, Just Paste It is the way
              to go!
            </Typography>
            <Typography
              variant="h2"
              color="secondary"
              sx={{
                mt: 1,
                fontSize: "1rem",
              }}
            >
              How it works
            </Typography>
            <Typography variant="body2" gutterBottom spacing={gridSpacing}>
              Write amazing text with our easy-to-use editor! Add cool things
              like pictures and links with just a few clicks. When {"you're"}{" "}
              finished, click the {"PUBLISH"} button to share your page with
              everyone! You can even create a special code for your page and use
              it on another device by clicking on get text button to see your
              work. Enjoy creating and have lots of fun!
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box xs={12} md={4}>
              <IconButton
                href="https://youtu.be/a6ileLlq_gQ"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Box
                  component="img"
                  style={{ cursor: "pointer", width: "100%", height: 250 }}
                  src={howItworks}
                  alt="How just paste it works"
                  loading="lazy"
                />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        <Typography
          variant="h2"
          color="secondary"
          sx={{
            mt: 1,
            fontSize: "1rem",
          }}
        >
          Create Your Own Text Code
        </Typography>
        <Typography variant="body1" gutterBottom>
          Create your personalized text code effortlessly on our website. Simply
          type in your text, set the code or generate it. Later, use the same
          code on any other device to access your saved content seamlessly.
          Experience hassle-free sharing and retrieval with our user-friendly
          platform.
        </Typography>
        <Typography
          variant="h2"
          color="secondary"
          sx={{
            mt: 1,
            fontSize: "1rem",
          }}
        >
          Text Editor with Powerful Text Formatting Tools
        </Typography>
        <Typography variant="body1" gutterBottom>
          Tell the difference between real people and bots. Let only humans
          access your content.
        </Typography>
        <Typography
          variant="h2"
          color="secondary"
          sx={{
            mt: 1,
            fontSize: "1rem",
          }}
        >
          Content that Expires in 24 Hours!
        </Typography>
        <Typography variant="body1" gutterBottom>
          Share text and messages worry-free! Our platform automatically sets an
          expiration time of 24 hours for all content. Once the time is up, your
          shared text will vanish, providing privacy and security to the
          content. Experience hassle-free and secure communication with our
          auto-delete-in-24-hours feature.
        </Typography>
        <Typography
          variant="h2"
          color="secondary"
          sx={{
            mt: 1,
            fontSize: "1rem",
          }}
        >
          Free Text Sharing: No Licenses Needed!
        </Typography>
        <Typography variant="body1" gutterBottom>
          Enjoy the freedom of free text editing and sharing without the need to
          purchase licenses for expensive software like Microsoft Word or other
          applications. Our platform offers an easy-to-use word doc format for
          typing and formatting text, all at no cost. Say goodbye to expensive
          subscriptions and embrace hassle-free content creation and sharing,
          absolutely free of charge!
        </Typography>
      </Paper>
    </MainCard>
  );
};

export default About;
