import React from "react";
// material-ui
import { Typography, Paper, Link, Divider } from "@mui/material";
// project imports
import MainCard from "../components/cards/MainCard";

class ShippingAndDeliveryPolicy extends React.PureComponent {
  render() {
    return (
      <MainCard
        title=""
        border={false}
        elevation={1}
        content={true}
        boxShadow
      >
        <Typography
          variant="h1"
          gutterBottom
          style={{ fontSize: "1.125rem", fontWeight: 500 }}
        >
          Delivery Policy
        </Typography>
        <Divider />
        <Typography variant="body1" paragraph></Typography>
        <Paper>
          <Typography variant="body2" gutterBottom>
            The premium feature will be delivered and activated immediately upon
            successful payment. Once the payment is processed, your premium
            access will be available for use within seconds. You will receive
            confirmation of activation via email.
          </Typography>
          <Typography
            variant="subtitle1"
            color="secondary"
            sx={{ mt: 1 }}
          >
            Delivery Time
          </Typography>
          <Typography variant="body2" gutterBottom>
            The premium features, including publishing your page on Google and
            creating a backlink, will be available for a period of one year
            from the date of purchase.
          </Typography>
          <Typography
            variant="subtitle1"
            color="secondary"
            sx={{ mt: 1 }}
          >
            Auto-Renewal of Subscription
          </Typography>
          <Typography variant="body2" gutterBottom>
            If you have opted for auto-renewal, the subscription will be
            automatically renewed at the end of the one-year period. You can
            cancel the auto-renewal before the renewal date by contacting our
            support team.
          </Typography>
          <Typography
            variant="subtitle1"
            color="secondary"
            sx={{ mt: 1 }}
          >
            Contact Us
          </Typography>
          <Typography variant="body2" gutterBottom>
            If you have any questions regarding the delivery of premium
            services, please contact our support team at{" "}
            <Link href="mailto:support@justpasteit.in">
              support@justpasteit.in
            </Link>
            .
          </Typography>
        </Paper>
      </MainCard>
    );
  }
}

export default ShippingAndDeliveryPolicy;
