import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Typography, Paper } from '@mui/material';

const allowedFileTypes = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/bmp',
  'image/webp',
  'image/svg+xml',
  'image/tiff',
  'image/x-icon',
  'image/vnd.microsoft.icon',
  'image/vnd.wap.wbmp',
  'image/ief',
  'image/jp2',
  'image/jpm',
  'image/jpx',
  'image/jxr',
  'image/ktx',
  'image/x-canon-cr2',
  'image/x-canon-crw',
  'image/x-ms-bmp',
  'image/x-nikon-nef',
  'image/x-nikon-nrw',
  'image/x-olympus-orf',
  'image/x-panasonic-rw2',
  'image/x-pentax-pef',
  'image/x-fuji-raf',
  'image/x-sony-arw',
  'application/pdf',
  'application/vnd.ms-excel',
  'audio/mp3',
  'audio/wav',
  'text/plain',
  'text/csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/msword', // for .doc files
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/*'
];

const maxSize = 20 * 1024 * 1024; // 20 MB in bytes

const FileUpload = ({ onFileUpload, files, toggleFileUploadModel }) => {
  const [errorMessage, setErrorMessage] = useState('');

  const onDrop = useCallback(
    (acceptedFiles) => {
      setErrorMessage('');
      const validFiles = acceptedFiles.filter((file) => {
        // Check file size
        if (file.size > maxSize) {
          setErrorMessage(`File "${file.name}" is too large. Max size allowed is 20MB.`);
          return false;
        }
        // Check file type
        if (!allowedFileTypes.includes(file.type)) {
          setErrorMessage(`File type "${file.type}" is not supported.`);
          return false;
        }
        if (files.some((item) => item.name === file.name)) {
          setErrorMessage(`File "${file.name}" already exist.`);
          return false;
        }
        if ((files.length + acceptedFiles.length) > 5) {
          setErrorMessage(`Cannot upload more than 5 files.`);
          return false;
        }
        return true;
      });

      if (validFiles.length > 0) {
        onFileUpload(acceptedFiles);
        toggleFileUploadModel();
      }
    },
    [onFileUpload, files, toggleFileUploadModel]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: allowedFileTypes
  });

  return (
    <div>
      <Paper elevation={3} {...getRootProps()} sx={{ padding: '20px', textAlign: 'center' }}>
        <input {...getInputProps()} />
        <Typography variant="h5">Drag &amp; Drop Files Here</Typography>
        <Typography variant="body2" color="textSecondary">
          or click to select files
        </Typography>
        {errorMessage && (
          <Typography variant="body2" color="error">
            {errorMessage}
          </Typography>
        )}
      </Paper>
    </div>
  );
};

export default FileUpload;
