import React from "react";
// material-ui
import { Typography, Paper, Link, Divider } from "@mui/material";
// project imports
import MainCard from "../components/cards/MainCard";

class CancellationAndRefundPolicy extends React.PureComponent {
  render() {
    return (
      <MainCard
        title=""
        border={false}
        elevation={1}
        content={true}
        boxShadow
      >
        <Typography
          variant="h1"
          gutterBottom
          style={{ fontSize: "1.125rem", fontWeight: 500 }}
        >
          Cancellation and Refund Policy
        </Typography>
        <Divider />
        <Typography variant="body1" paragraph></Typography>
        <Paper>
          <Typography variant="body2" gutterBottom>
            Once the premium subscription is purchased, cancellation is not
            allowed within the subscription period. The premium subscription is
            non-refundable and valid for the entire duration of one year from
            the date of purchase.
          </Typography>
          <Typography
            variant="subtitle1"
            color="secondary"
            sx={{ mt: 1 }}
          >
            Cancellation of Auto-Renewal
          </Typography>
          <Typography variant="body2" gutterBottom>
            Users can cancel the auto-renewal of their subscription anytime
            before the renewal date. The cancellation will only affect future
            renewals, and the current premium subscription will remain active
            until the end of its one-year period.
          </Typography>
          <Typography
            variant="subtitle1"
            color="secondary"
            sx={{ mt: 1 }}
          >
            No Refund Policy
          </Typography>
          <Typography variant="body2" gutterBottom>
            As the premium service is activated immediately upon purchase, we do
            not offer refunds once the payment is processed. By purchasing the
            premium subscription, you agree to our no-refund policy.
          </Typography>
          <Typography
            variant="subtitle1"
            color="secondary"
            sx={{ mt: 1 }}
          >
            Contact Us
          </Typography>
          <Typography variant="body2" gutterBottom>
            If you have any questions or concerns regarding the cancellation and
            refund policy, please contact our support team at{" "}
            <Link href="mailto:support@justpasteit.in">
              support@justpasteit.in
            </Link>
            .
          </Typography>
        </Paper>
      </MainCard>
    );
  }
}

export default CancellationAndRefundPolicy;
