import React from "react";
import { ReactComponent as LogSvg } from '../assets/images/COPYPASTE2.svg';
// ==============================|| LOGO SVG ||============================== //
const Logo = () => {
  return (
    <LogSvg />
  );
};

export default Logo;
