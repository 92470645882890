import React from "react";
// material-ui
import {
  Typography,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
// project imports
import MainCard from "../components/cards/MainCard";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

// ==============================|| SAMPLE PAGE ||============================== //

class Terms extends React.PureComponent {
  render() {
    return (
      <MainCard
        title=""
        border={false}
        elevation={1}
        content={true}
        boxShadow
      >
        <Typography
          variant="h1"
          gutterBottom
          style={{ fontSize: "1.125rem", fontWeight: 500 }}
        >
          Terms
        </Typography>
        <Divider />
        <Typography variant="body1" paragraph></Typography>
        <Paper>
          <Typography variant="body2" gutterBottom>
            Welcome to Just Paste It! These Terms of Service govern your use of
            our website and services, so please read them carefully. By
            accessing or using Just Paste It{" "}
            {'("referred to as "the website" or "our services")'}, you agree to
            be bound by these Terms. If you do not agree with any part of these
            Terms, you may not use our website
          </Typography>
          <Typography
            variant="subtitle1"
            color="secondary"
            sx={{
              mt: 1,
            }}
          >
            Use of Our Services:
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary="Just Paste It provides a platform where users can generate and share text codes. You are solely responsible for the content you paste and generate using our services" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary="You must not use our services for any unlawful, harmful, or abusive purposes that violate applicable laws or regulations" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary="We reserve the right to terminate or suspend your access to our services at any time without prior notice for any violation of these Terms" />
            </ListItem>
          </List>
          <Typography
            variant="subtitle1"
            color="secondary"
            sx={{
              mt: 1,
            }}
          >
            User Conduct:
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary="You agree to use our services in compliance with all applicable laws and regulations" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary="You must not engage in any unauthorized access to our website or attempt to disrupt our services or server" />
            </ListItem>
          </List>
          <Typography
            variant="subtitle1"
            color="secondary"
            sx={{
              mt: 1,
            }}
          >
            Content Ownership and Copyright:
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary="You retain ownership of the content you paste and generate using our services" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary="By using our website, you grant us a non-exclusive, worldwide, royalty-free license to use, display, and distribute the content you generate for the purpose of providing our services" />
            </ListItem>
          </List>
          <Typography
            variant="subtitle1"
            color="secondary"
            sx={{
              mt: 1,
            }}
          >
            Privacy Policy:
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary="Your privacy is important to us. Please review our Privacy Policy Page to understand how we collect, use, and safeguard your personal information" />
            </ListItem>
          </List>
          <Typography
            variant="subtitle1"
            color="secondary"
            sx={{
              mt: 1,
            }}
          >
            Content Expiry
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary="Content generated using our services will self-destruct and expire automatically after 24 hours" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary="We are not responsible for any loss of data or content after the expiration period." />
            </ListItem>
          </List>
          <Typography
            variant="subtitle1"
            color="secondary"
            sx={{
              mt: 1,
            }}
          >
            Third-Party Services
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary="Our website may include links to third-party websites or services that are not under our control. We are not responsible for the content, policies, or practices of these third-party websites" />
            </ListItem>
          </List>
          <Typography
            variant="subtitle1"
            color="secondary"
            sx={{
              mt: 1,
            }}
          >
            Disclaimer of Warranties
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary="Our services are provided on an as is basis without warranties of any kind, whether express or implied" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary="We do not guarantee the accuracy, reliability, or availability of our services" />
            </ListItem>
          </List>
          <Typography
            variant="subtitle1"
            color="secondary"
            sx={{
              mt: 1,
            }}
          >
            Limitation of Liability:
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary=" In no event shall Just Paste It be liable for any direct, indirect, incidental, special, or consequential damages arising from the use of our website" />
            </ListItem>
          </List>
          <Typography
            variant="subtitle1"
            color="secondary"
            sx={{
              mt: 1,
            }}
          >
            Changes to the Terms:
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary="We may update these Terms from time to time. Any changes will be posted on this page, and the date of the last update will be indicated at the top" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary="By continuing to use our services after any changes to these Terms, you agree to be bound by the updated Terms" />
            </ListItem>
          </List>
          <Typography
            variant="subtitle1"
            color="secondary"
            sx={{
              mt: 1,
            }}
          >
            Contact Us
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary="If you have any questions or concerns about these Terms, please contact us at support@justpasteit.in"></ListItemText>
            </ListItem>
          </List>

          <Typography
            variant="h1"
            gutterBottom
            sx={{
              mt: 1,
              fontSize: "10px",
            }}
          >
            By using Just Paste It, you agree to these Terms. Thank you for
            using our services!
          </Typography>
        </Paper>
      </MainCard>
    );
  }
}

export default Terms;
