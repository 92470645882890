import React from "react";
// material-ui
import { Typography, Paper, Link, Divider } from "@mui/material";
// project imports
import MainCard from "../components/cards/MainCard";
// ==============================|| SAMPLE PAGE ||============================== //

class PrivacyPolicy extends React.PureComponent {
  render() {
    return (
      <MainCard
        title=""
        border={false}
        elevation={1}
        content={true}
        boxShadow
      >
        <Typography
          variant="h1"
          gutterBottom
          style={{ fontSize: "1.125rem", fontWeight: 500 }}
        >
          Privacy and Policy
        </Typography>
        <Divider />
        <Typography variant="body1" paragraph></Typography>
        <Paper>
          <Typography variant="body2" gutterBottom>
            At Just Paste It, accessible via www.justpasteit.in, we take your
            privacy and data protection seriously. This Privacy Policy outlines
            how we collect, use, and safeguard your personal information when
            you use our website and services. We are committed to ensuring that
            your privacy is protected, and we strive to be transparent about our
            data practices.
          </Typography>
          <Typography
            variant="subtitle1"
            color="secondary"
            sx={{
              mt: 1,
            }}
          >
            Information Collection and Usage
          </Typography>
          <Typography variant="body2" gutterBottom>
            We may collect certain information from you when you use our
            website, such as your IP address, device information, and browsing
            behavior. This data is used to improve your user experience, analyze
            website traffic, and personalize your content.
          </Typography>
          <Typography
            variant="subtitle1"
            color="secondary"
            sx={{
              mt: 1,
            }}
          >
            User-Provided Content
          </Typography>
          <Typography variant="body2" gutterBottom>
            The text content you paste and generate using our services is solely
            under your control and responsibility. We do not access, read, or
            store the text content shared via our platform. Your text data is
            self-destructed automatically after 24 hours.
          </Typography>
          <Typography
            variant="subtitle1"
            color="secondary"
            sx={{
              mt: 1,
            }}
          >
            Use of Cookies
          </Typography>
          <Typography variant="body1" gutterBottom>
            We use cookies and similar tracking technologies to enhance your
            browsing experience. Cookies help us remember your preferences,
            optimize website performance, and analyze user behavior. You can
            modify your browser settings to manage cookie preferences.
          </Typography>
          <Typography
            variant="subtitle1"
            color="secondary"
            sx={{
              mt: 1,
            }}
          >
            Third-Party Services
          </Typography>
          <Typography variant="body1" gutterBottom>
            We may use third-party services and tools to improve our website and
            services. These third parties may have access to certain data to
            provide their services, but they are bound by their own privacy
            policies.
          </Typography>
          <Typography
            variant="subtitle1"
            color="secondary"
            sx={{
              mt: 1,
            }}
          >
            Data Security
          </Typography>
          <Typography variant="body1" gutterBottom>
            We employ industry-standard security measures to protect your
            personal information from unauthorized access, alteration, or
            disclosure. However, please note that no data transmission over the
            internet is 100% secure, and we cannot guarantee absolute data
            security.
          </Typography>
          <Typography
            variant="subtitle1"
            color="secondary"
            sx={{
              mt: 1,
            }}
          >
            {"Children's Privacy:"}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Our services are not intended for children under the age of 13. We
            do not knowingly collect personal information from minors. If you
            believe a child has provided us with their data without parental
            consent, please contact us immediately.
          </Typography>
          <Typography
            variant="subtitle1"
            color="secondary"
            sx={{
              mt: 1,
            }}
          >
            Changes to Privacy Policy
          </Typography>
          <Typography variant="body1" gutterBottom>
            We reserve the right to update this Privacy Policy as our services
            evolve or to comply with legal requirements. Any changes will be
            posted on this page, and the date of the last update will be
            indicated at the top.
          </Typography>
          <Typography
            variant="h1"
            gutterBottom
            sx={{
              mt: 1,
              fontSize: "10px",
            }}
          >
            By using Just Paste It, you consent to the terms and conditions
            outlined in this Privacy Policy. If you have any questions or
            concerns about our data practices or this policy, please contact us
            at{" "}
            <Link href="mailto:support@justpasteit.in">
              support@justpasteit.in
            </Link>
            .
          </Typography>
        </Paper>
      </MainCard>
    );
  }
}

export default PrivacyPolicy;
