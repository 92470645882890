import React from "react";
// material-ui
import {
  Typography,
  Paper,
  Divider,
} from "@mui/material";
import MainCard from "../../components/cards/MainCard";
import godaddyhostingweb from "../../assets/images/blog/godaddy_hosting_web.webp";
import GoDaddy_Login_page from "../../assets/images/blog/GoDaddy_Login_page.webp";
import Domain_Section_GoDaddy from "../../assets/images/blog/Domain_Section_GoDaddy.webp";
import Edit_Name_server_DoDaddy from "../../assets/images/blog/Edit_Name_server_DoDaddy.webp";
import Edit_NameServer_in_GoDaddy_box from "../../assets/images/blog/Edit_NameServer_in_GoDaddy_box.webp";
import Hostinger_Nameserver from "../../assets/images/blog/Hostinger_Nameserver.webp";

class ReactSSR extends React.PureComponent {
  handleDownloadFile = (filename) => {
    // Replace 'http://localhost:8994' with your actual server URL
    const fileURL = `https://justpasteit.in/${filename}`;

    // Fetch the file and trigger the download
    fetch(fileURL)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = filename.split("-")[1] || filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  };

  render() {
    return (
      <MainCard
        title="How to Connect GoDaddy Domain to Hostinger"
        border={false}
        elevation={1}
        content={true}
      >
        <Typography
          variant="h1"
          gutterBottom
          style={{ fontSize: "1.125rem", fontWeight: 500 }}
        >
          Step-by-Step Guide to Connecting Your GoDaddy Domain to Hostinger
          Hosting
        </Typography>
        <img
          src={godaddyhostingweb}
          alt="Connect GoDaddy domain to Hostinger"
          style={{ width: "100%", height: "50%" }}
        />

        <Divider />
        <Typography variant="body1" paragraph></Typography>
        <Paper>
          <Typography variant="body2" margin={1} gutterBottom>
            In this guide, we will walk you through the process of connecting
            your GoDaddy domain to Hostinger hosting. Follow these simple steps
            to ensure a smooth connection and get your website up and running.
          </Typography>
          <Typography
            variant="h2"
            color="secondary"
            margin={1}
            sx={{
              fontSize: "1rem",
            }}
          >
            Step 1: Log in to Your GoDaddy Account
          </Typography>
          <Typography variant="body2" margin={1} gutterBottom>
            First, log in to your GoDaddy account. Once logged in, navigate to
            your domain list by clicking on <b>My Products</b> and select the
            domain you want to connect to Hostinger.
          </Typography>
          <img
            src={GoDaddy_Login_page}
            alt="GoDaddy Login"
            style={{ width: "100%", height: "50%" }}
          />

          <Typography
            variant="h2"
            color="secondary"
            margin={1}
            sx={{
              fontSize: "1rem",
            }}
          >
            Step 2: Go to the Domain Section
          </Typography>
          <Typography variant="body2" margin={1} gutterBottom>
            In the domain section, locate your domain and click on <b>Manage</b>
            . This is where you will update the DNS records to point to
            Hostinger's servers.
          </Typography>
          <img
            src={Domain_Section_GoDaddy}
            alt="DNS Management"
            style={{ width: "100%", height: "50%" }}
          />

          <Typography
            variant="h2"
            color="secondary"
            margin={1}
            sx={{
              fontSize: "1rem",
            }}
          >
            Step 3: Update Nameservers
          </Typography>
          <Typography variant="body2" margin={1} gutterBottom>
            On the DNS Management page, you will see a section for nameservers.
            Replace the existing GoDaddy nameservers with Hostinger's
            nameservers by clicking on <b>Change Nameservers</b>.
          </Typography>
          <img
            src={Edit_Name_server_DoDaddy}
            alt="Update Nameservers"
            style={{ width: "100%", height: "50%" }}
          />
          <Typography
            variant="h2"
            color="secondary"
            margin={1}
            sx={{
              fontSize: "1rem",
            }}
          >
            Step 4: Enter Nameserver Details
          </Typography>
          <Typography variant="body2" margin={1} gutterBottom>
            Enter the nameserver records of your Hostinger account here:
            <ul>
              <li>ns1.dns-parking.com</li>
              <li>ns2.dns-parking.com</li>
            </ul>
            Save the changes.
          </Typography>
          <img
            src={Edit_NameServer_in_GoDaddy_box}
            alt="Update Nameservers"
            style={{ width: "100%", height: "50%" }}
          />
          <Typography
            variant="h2"
            color="secondary"
            margin={1}
            sx={{
              fontSize: "1rem",
            }}
          >
            Step 5: Log in to Your Hostinger Account
          </Typography>
          <Typography variant="body2" margin={1} gutterBottom>
            Now, log in to your Hostinger account. Go to the "Hosting" section
            and select the hosting plan you want to use with your domain. Click
            on <b>Change Nameservers</b> and copy the details of your hosting.
          </Typography>
          <img
            src={Hostinger_Nameserver}
            alt="Hostinger Login"
            style={{ width: "100%", height: "50%" }}
          />

          <Typography
            variant="h2"
            color="secondary"
            margin={1}
            sx={{
              fontSize: "1rem",
            }}
          >
            Step 6: Verify the Connection
          </Typography>
          <Typography variant="body2" margin={1} gutterBottom>
            Finally, verify the connection. It may take a few hours for the DNS
            changes to propagate. You can use online tools to check if your
            domain is pointing to Hostinger's servers.
          </Typography>

          <Typography
            variant="h2"
            color="secondary"
            margin={1}
            sx={{
              fontSize: "1rem",
            }}
          >
            Conclusion
          </Typography>
          <Typography variant="body2" margin={1} gutterBottom>
            Connecting your GoDaddy domain to Hostinger hosting is a
            straightforward process. By following these steps, you can ensure
            that your website is properly set up and accessible. If you
            encounter any issues, Hostinger's support team is available to
            assist you.
          </Typography>
        </Paper>
      </MainCard>
    );
  }
}

export default ReactSSR;
