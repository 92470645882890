import React from 'react';
import { Grid, Card, CardContent, CardMedia, Typography, Button, Link, Badge } from '@mui/material';
import HostingerImage from '../../assets/images/products/Hostinger.png';
import BluehostImage from '../../assets/images/products/Bluehost.png';
import SiteGroundImage from "../../assets/images/products/SiteGround.png";
import NameCheapImage from "../../assets/images/products/NameCheap.jpg";

const HostingCard = () => {
  const hostingData = [
    { name: 'Hostinger', image: HostingerImage, url: 'https://hostinger.in?REFERRALCODE=1AMPLIFYING06', badge: 'Best WordPress Hosting', price: '$2.99/month' },
    { name: 'Bluehost', image: BluehostImage, url: 'https://www.bluehost.in/hosting', price: '$3.75/month' },
    { name: 'SiteGround', image: SiteGroundImage, url: 'https://world.siteground.com/web-hosting.htm', price: '$2.99/month' },
    { name: 'NameCheap', image: NameCheapImage, url: 'https://www.namecheap.com/hosting/shared/', price: '$1.98/month' },
  ];

  return (
    <Grid container spacing={3}>
      {hostingData.map((hosting, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
          <Card>
            <div style={{ textAlign: 'center' }}>
            <Badge badgeContent="10% Cashback" color="error" sx={{ marginTop: 1 }}>
              <CardMedia
                component="img"
                sx={{ width: 150, objectFit: 'cover', margin: 'auto' }}
                image={hosting.image}
                alt={`${hosting.name} Hosting`}
              />
              </Badge>
            </div>
            <CardContent>
              <Typography variant="body2" color="textSecondary">
                Price: {hosting.price}
              </Typography>
                <Typography variant="body1" color="textPrimary" align="center">
                  {hosting.name}
                </Typography>
            </CardContent>
            <Button variant="contained" target="_blank" color="secondary" fullWidth>
              <Link href={hosting.url} target="_blank" color="inherit" underline="none">
                Get Discount
              </Link>
            </Button>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default HostingCard;
