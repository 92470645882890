import PropTypes from "prop-types";
/* eslint-disable */
// material-ui
import {
  Box,
  Card,
  Grid,
  Typography,
  Button,
  IconButton,
  Divider,
  Paper,
  CircularProgress,
} from "@mui/material";

// project imports
import MainCard from "./cards/MainCard";
import { gridSpacing } from "../store/constant";

import React, { useState } from "react";
import BasicModal from "./TextModel";
import GetTextModal from "./getTextModel";
import FileUploadModal from "./FileuploadModel";
import axios from "../Axios/axiosConfig";
import CustomSnackbar from "./snackbar/CustomSnackbar";
import AttachmentIcon from "@mui/icons-material/Attachment";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/GetApp";
import useMediaQuery from "@mui/material/useMediaQuery";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import LinearProgress from "@mui/material/LinearProgress";

// ===============================|| COLOR BOX ||=============================== //

const ColorBox = ({ bgcolor, title, data, dark }) => (
  <>
    <Card sx={{ mb: 3 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          py: 4.5,
          bgcolor,
          color: dark ? "grey.800" : "#ffffff",
        }}
      >
        {title && (
          <Typography variant="subtitle1" color="inherit">
            {title}
          </Typography>
        )}
        {!title && <Box sx={{ p: 1.15 }} />}
      </Box>
    </Card>
  </>
);

ColorBox.propTypes = {
  bgcolor: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.object.isRequired,
  dark: PropTypes.bool,
};

// ===============================|| UI COLOR ||=============================== //

const PasteText = () => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [error, setError] = React.useState("");
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [snackbarType, setSnackbarType] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [openFileUpload, setOpenFileUpload] = React.useState(false);
  const [getTextOpen, setgetTextOpen] = React.useState(false);
  const [serverImages, setServerImages] = React.useState([]);
  const [editorHtml, setEditorHtml] = useState("");
  const [loading, setLoading] = React.useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  //file upload feature
  const [files, setFiles] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const handlePostText = () => {
    setShowSnackbar(false);
    setOpen(!open);
  };
  const toggleGetTextModel = () => {
    setShowSnackbar(false);
    setgetTextOpen(!getTextOpen);
    setError("");
  };
  const fetchData = (codeText) => {
    // Display loading spinner while the request is in progress
    setLoading(true);

    axios
      .get(`/texts/${codeText}`)
      .then((response) => {
        let editorContent = JSON.parse(response.data.content);

        if (
          response.data &&
          response.data.files &&
          response.data.files.length > 0
        ) {
          setServerImages([...response.data.files]);
        }
        setFiles([]); // Reset the old upload to blank
        setEditorHtml(editorContent);
        toggleGetTextModel();
        // Show snack bar
        setSnackbarType("fetchSuccess");
        toggleSnackBar();

        // Hide loading spinner after successful response
        setLoading(false);
      })
      .catch((error) => {
        if (error.response?.status == 404) {
          setError("No text found with the given code");
        } else {
          toggleGetTextModel();
          // Show snack bar
          setSnackbarType("failed");
          toggleSnackBar();
        }
        // Hide loading spinner after error
        setLoading(false);

        // Handle the error or display it as needed
        console.error(error);
      });
  };

  const getTextData = (codeText) => {};

  const toggleSnackBar = () => {
    setShowSnackbar(!showSnackbar);
  };

  const toggleFileUploadModel = () => {
    setOpenFileUpload(!openFileUpload);
  };

  const handleFileUpload = (uploadedFiles) => {
    // Process the uploaded files here, e.g., send them to the server
    if (uploadedFiles.length > 0) {
      // Generate image previews for valid image files
      const imageFiles = uploadedFiles.filter((file) =>
        file.type.startsWith("image/")
      );
      const newImagePreviews = [...imagePreviews];
      imageFiles.forEach((imageFile) => {
        const imageUrl = URL.createObjectURL(imageFile);
        newImagePreviews.push({ file: imageFile, previewUrl: imageUrl });
      });
      setImagePreviews(newImagePreviews);
      setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
    }
  };

  const handleDeleteFile = (fileToDelete) => {
    // Revoke data URIs for image previews
    if (fileToDelete.type.startsWith("image/")) {
      const updatedImagePreviews = imagePreviews.filter(
        (preview) => preview.file !== fileToDelete
      );
      setImagePreviews(updatedImagePreviews);
    }

    const updatedFiles = files.filter((file) => file !== fileToDelete);
    setFiles(updatedFiles);
  };

  function handleDownloadFile(filename) {
    // Replace 'http://localhost:8994' with your actual server URL
    const fileURL = `https://justpasteit.in/uploads/${filename}`;

    // Fetch the file and trigger the download
    fetch(fileURL)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = filename.split("-")[1] || filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  }

  const handleChange = (content) => {
    // Regular expression to match the specific URL
    const forbiddenUrlPattern = /https:\/\/cdn\.discordapp\.com/g;
    
    // Check if the content contains the forbidden URL
    if (!forbiddenUrlPattern.test(content)) {
      setEditorHtml(content)
    } else {
      alert("Links or text from cdn.discordapp.com are not allowed. Your IP address and email have been reported");
      setEditorHtml("");
    }
  };

  const formats = [
    "header",
    "font",
    "size",
    "color",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "align",
  ];
  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"], // toggled buttons

      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent

      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ font: [] }, { color: [] }],
      [{ align: [] }, "link", "image"],
    ],
    clipboard: {
      matchVisual: false, // Disable Quill's default pasting
    },
    history: {
      delay: 2000,
      maxStack: 500,
      userOnly: true,
    },
  };

  let quill;
  const quillRef = (ref) => {
    if (ref) {
      quill = ref.getEditor();

      // Define the custom image handler
      quill.getModule("toolbar").addHandler("image", () => {
        // Trigger the file input to open when the custom image button is clicked
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.addEventListener("change", () => {
          const file = input.files[0];
          if (file) {
            // Handle the image upload
            handleImageUpload(file, quill);
          }
        });
        input.click();
      });
    }
  };

  // Implement the custom image handler function
  // Implement the custom image handler function
  const handleImageUpload = (image, quill) => {
    //on Image upload Loading
    const range = quill.getSelection();
    let imageUrl;
    // setLoading(true); // Show loading spinner
    // Once you have the image URL, insert it into the editor
    const formData = new FormData();
    formData.append("file", image);

    // Send the image to your server and get the image URL
    axios
      .post("/texts/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Set the content type to FormData
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      })
      .then((response) => {
        if (response.status === 201) {
          return response.data;
        } else {
          console.log("Image upload failed.");
        }
      })
      .then((data) => {
        console.log("range", range);
        imageUrl = data.fileName;
        quill.insertEmbed(
          range.index,
          "image",
          `https://justpasteit.in/uploads/${imageUrl}`
        );
        const updatedEditorHtml = quill.root.innerHTML;
        setEditorHtml(updatedEditorHtml);
        console.log("quill after image", quill);
      })
      .catch((error) => {
        console.error("Image upload error:", error);
      })
      .finally(() => {
        setUploadProgress(0); // Hide loading spinner
      });
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <CircularProgress color="secondary" size={64} />
        </div>
      ) : (
        <MainCard content={true}>
          <CustomSnackbar
            showSnackbar={showSnackbar}
            snackbarType={snackbarType}
          />

          <BasicModal
            isOpen={open}
            toggleSnackBar={toggleSnackBar}
            setSnackbarType={setSnackbarType}
            editorState={editorHtml} //old code of editoreState
            handlePostText={handlePostText}
            files={files}
            setUploadProgress={setUploadProgress}
          />
          <GetTextModal
            isMobile={isMobile}
            isOpen={getTextOpen}
            toggleGetTextModel={toggleGetTextModel}
            fetchData={fetchData}
            error={error}
          />
          <FileUploadModal
            handleFileUpload={handleFileUpload}
            files={files}
            isOpen={openFileUpload}
            toggleFileUploadModel={toggleFileUploadModel}
          />
          <Grid
            container
            spacing={gridSpacing}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item>
              {/* <Typography variant="h2">Just Paste It</Typography> */}
              <Typography
                variant="h1"
                gutterBottom
                style={{ fontSize: "1rem", fontWeight: 500 }}
              >
                Just Paste It
              </Typography>
            </Grid>
            <Grid item>
              <Button
                disableElevation
                fullWidth
                size={isMobile ? "small" : "large"}
                type="submit"
                variant="contained"
                color="secondary"
                onClick={toggleGetTextModel}
              >
                Get Text
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Grid item mb={1} xs={12} sm={12} md={12} lg={12}>
                <Paper
                  m={1}
                  data-text-editor="name"
                  style={{
                    height: "380px",
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    overflow: "auto",
                  }}
                >
                  <ReactQuill
                    ref={quillRef}
                    style={{
                      height: "210px",
                      minHeight: "200px",
                      maxHeight: isMobile ? "380px" : "330px",
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                    }}
                    theme="snow"
                    value={editorHtml}
                    onChange={handleChange}
                    modules={modules}
                    formats={formats}
                    placeholder="Write something..."
                    bounds={`[data-text-editor="name"]`}
                  />
                </Paper>
              </Grid>
              {uploadProgress > 0 && (
                <LinearProgress variant="determinate" value={uploadProgress} />
              )}
              <Grid container spacing={gridSpacing} direction="row">
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {serverImages.length > 0 ? (
                    <Grid container spacing={gridSpacing}>
                      {serverImages.map((file, index) => {
                        return (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            lg={3}
                            key={file.filename}
                          >
                            <div
                              style={{
                                width: "100%",
                                height: "75%", // Adjust the height
                                padding: "8px",
                                border: "1px solid #e0e0e0",
                                borderRadius: "8px",
                                boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
                                position: "relative",
                              }}
                            >
                              <IconButton
                                onClick={() =>
                                  handleDownloadFile(file.generatedFilename)
                                }
                                style={{
                                  position: "absolute",
                                  top: "4px",
                                  right: "4px",
                                  zIndex: 1,
                                  backgroundColor: "#fff",
                                }}
                              >
                                <DownloadIcon />
                              </IconButton>

                              <Grid
                                container
                                alignItems="center"
                                spacing={gridSpacing}
                                style={{ width: "100%", height: "100%" }}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  md={6}
                                  style={{ textAlign: "center" }}
                                >
                                  {file.type.startsWith("image/") ? (
                                    <Box
                                      component="img"
                                      sx={{
                                        maxWidth: "100%",
                                        maxHeight: "100px",
                                        display: "block",
                                        margin: "0 auto",
                                      }}
                                      onLoad={() => {
                                        URL.revokeObjectURL(
                                          imagePreviews.find(
                                            (preview) => preview.file === file
                                          )?.previewUrl
                                        );
                                      }}
                                      alt="just paste it images"
                                      src={`https://justpasteit.in/uploads/${file.generatedFilename}`}
                                    />
                                  ) : (
                                    <AttachmentIcon
                                      sx={{
                                        fontSize: "48px",
                                        color: "#757575",
                                        display: "block",
                                        margin: "0 auto",
                                      }}
                                    />
                                  )}
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  md={6}
                                  style={{ textAlign: "center" }}
                                >
                                  <Typography
                                    variant="body2"
                                    align="center" // Align center for mobile view
                                    style={{
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                      maxWidth: "100%",
                                    }}
                                  >
                                    {file.filename}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </div>
                            <Divider sx={{ my: gridSpacing }} />
                          </Grid>
                        );
                      })}
                    </Grid>
                  ) : (
                    <Grid container spacing={gridSpacing}>
                      {files.map((file, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={file.name}>
                          <div
                            style={{
                              width: "100%",
                              height: "75%",
                              padding: "8px",
                              border: "1px solid #e0e0e0",
                              borderRadius: "8px",
                              boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
                              position: "relative", // Make the container relative for positioning the close icon
                            }}
                          >
                            {/* Close Icon for removing the card */}
                            <IconButton
                              onClick={() => handleDeleteFile(file)}
                              style={{
                                position: "absolute",
                                top: "4px", // Adjust the top position
                                right: "4px", // Adjust the right position
                                zIndex: 1, // Ensure the icon is above other content
                                backgroundColor: "#fff", // Background color for the icon
                              }}
                            >
                              <CloseIcon />
                            </IconButton>

                            <Grid
                              container
                              alignItems="center"
                              spacing={gridSpacing}
                              style={{ width: "100%", height: "100%" }}
                            >
                              {/* Rest of the content */}
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={6}
                                style={{ textAlign: "center" }}
                              >
                                {file.type.startsWith("image/") ? (
                                  <Box
                                    component="img"
                                    sx={{
                                      maxWidth: "100%",
                                      maxHeight: "100px",
                                      display: "block",
                                      margin: "0 auto",
                                    }}
                                    onLoad={() => {
                                      URL.revokeObjectURL(
                                        imagePreviews.find(
                                          (preview) => preview.file === file
                                        )?.previewUrl
                                      );
                                    }}
                                    alt="just paste it images"
                                    src={
                                      imagePreviews.find(
                                        (preview) => preview.file === file
                                      )?.previewUrl
                                    }
                                  />
                                ) : (
                                  <AttachmentIcon
                                    sx={{
                                      fontSize: "48px",
                                      color: "#757575",
                                      display: "block",
                                      margin: "0 auto",
                                    }}
                                  />
                                )}
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                <Typography
                                  variant="body2"
                                  align="center"
                                  style={{
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    maxWidth: "100%",
                                  }}
                                >
                                  {file.name}
                                </Typography>
                              </Grid>
                            </Grid>
                          </div>
                          <Divider sx={{ my: gridSpacing }} />
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Box sx={{ mt: 2 }}>
                    <Button
                      disableElevation
                      fullWidth
                      size={isMobile ? "small" : "large"}
                      type="submit"
                      variant="contained"
                      color="secondary"
                      onClick={toggleFileUploadModel}
                    >
                      Upload File
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Box sx={{ mt: 2 }}>
                    <Button
                      disableElevation
                      fullWidth
                      size={isMobile ? "small" : "large"}
                      type="submit"
                      variant="contained"
                      color="secondary"
                      onClick={handlePostText}
                    >
                      Publish
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MainCard>
      )}
    </>
  );
};

export default PasteText;
