import React from "react";
import { Grid, Paper, Typography, Avatar, Box } from "@mui/material";
import { useLocation, Link } from "react-router-dom";

import ReactSSR from "./ReactSSR";
import TailwindTutorial from "./TailwindCss";
import ReactGoDaddy from "./ReactGoDaddy";

import ReactssrImg from "../../assets/images/blog/Reactssr1.webp";
import tailwindcss from "../../assets/images/blog/Tailwindcss1.webp";
import godaddyhostingweb from "../../assets/images/blog/godaddy_hosting_web.webp";

const BlogPage = () => {
  const location = useLocation();

  const yourListOfPosts = [
    {
      id: 1,
      title: "Reactjs Server Side Rendering - Implement react ssr",
      date: "2024-01-01",
      url: "/blog/react-ssr-seo-react-helmet",
      imageUrl: ReactssrImg,
    },
    {
      id: 2,
      title: "Learn Tailwindcss with Responsive Website | nextjs",
      date: "2024-01-02",
      url: "/blog/tailwindcss",
      imageUrl: tailwindcss,
    },
    {
      id: 3,
      title: "GoDaddy Domain to Hostinger Hosting",
      date: "2024-07-26",
      url: "/blog/godaddy-hosting-web",
      imageUrl: godaddyhostingweb,
    },
  ];

  return (
    <Grid container spacing={3}>
      {/* Left Side - Post Content */}
      <Grid item xs={12} md={8}>
        {location &&
        location.pathname === "/blog/react-ssr-seo-react-helmet" ? (
          <ReactSSR />
        ) : location.pathname === "/blog/godaddy-hosting-web" ? (
          <ReactGoDaddy />
        ) : (
          <TailwindTutorial />
        )}
      </Grid>

      {/* Right Side - Popular Posts List */}
      <Grid item xs={12} md={4}>
        <Paper sx={{ p: 3 }}>
          <Typography variant="h3" mb={2}>
            Popular Posts
          </Typography>
          {yourListOfPosts.map((post) => (
            <Link
              to={post.url}
              key={post.id}
              style={{ textDecoration: "none" }}
            >
              <Paper
                key={post.id}
                sx={{
                  cursor: "pointer",
                  p: 2,
                  mb: 2,
                  display: "flex",
                  alignItems: "center",
                  overflow: "hidden", // Add overflow hidden to prevent horizontal scrolling
                  "&:hover": { backgroundColor: "#f0f0f0" },
                }}
              >
                {/* Image Section (On the Right Side) */}
                <Avatar
                  src={post.imageUrl}
                  alt={post.title}
                  sx={{
                    marginLeft: 1,
                    marginRight: 2,
                    width: 80, // Adjust the width as needed
                    height: 60, // Adjust the height as needed
                    borderRadius: "10px", // Add rounded borders
                  }}
                />
                {/* Text Section (On the Left Side) */}
                <Box>
                  <Typography
                    variant="h1"
                    sx={{
                      fontSize: "1rem",
                    }}
                  >
                    {post.title}
                  </Typography>
                  <Typography variant="caption">{post.date}</Typography>
                </Box>
              </Paper>
            </Link>
          ))}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default BlogPage;
