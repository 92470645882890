import React from "react";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Link,
  Rating,
} from "@mui/material";
import psychologyOfMoneyImage from "../../assets/images/products/Amazon/psychologyOfMoney.jpg";
import richDadImage from "../../assets/images/products/Amazon/richDad.jpg";
import winFriendsImage from "../../assets/images/products/Amazon/winFriends.jpg";
import AmClubImage from "../../assets/images/products/Amazon/AmClub.jpg";

const BookCards = () => {
  const hostingData = [
    {
      name: "Psychology of Money",
      image: psychologyOfMoneyImage,
      url: "https://amzn.to/3wDoOH0",
      rating: 4.4,
      price: "₹275",
    },
    {
        name: "Rich Dad Poor Dad",
        image: richDadImage,
        url: "https://amzn.to/48KIWnW",
        rating: 4.5,
        price: "₹310",
      },
      {
        name: "How to Win Friends",
        image: winFriendsImage,
        url: "https://amzn.to/4c22tDn",
        rating: 4.5,
        price: "₹99",
      },
      {
        name: "The 5 AM Club",
        image: AmClubImage,
        url: "The 5 AM Club",
        rating: 4.5,
        price: "₹240",
      },
  ];

  return (
    <Grid container spacing={3}>
      {hostingData.map((hosting, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
          <Card>
            <div style={{ textAlign: "center" }}>
              <CardMedia
                component="img"
                sx={{ width: 150, objectFit: "cover", margin: "auto" }}
                image={hosting.image}
                alt={`${hosting.name} Book`}
              />
            </div>
            <CardContent>
              <Typography variant="h4" color="textSecondary">
                {hosting.name}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Price: {hosting.price}
              </Typography>
              <Rating value={hosting.rating} precision={0.1} readOnly />
            </CardContent>
            <Button variant="contained" color="secondary" fullWidth>
              <Link href={hosting.url} color="inherit" underline="none">
                Get Discount
              </Link>
            </Button>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default BookCards;
