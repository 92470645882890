import React from "react";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { ButtonBase } from "@mui/material";

// project imports
import Logo from "./Logo";
import { MENU_OPEN } from "../store/actions";
import { useNavigate } from 'react-router-dom';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
  const defaultId = useSelector((state) => state.customization.defaultId);
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const handleLogoClick = () => {
    dispatch({ type: MENU_OPEN, id: defaultId });
    navigate("/"); // Redirect to home page
  };
  return (
    <ButtonBase disableRipple onClick={handleLogoClick}>
      <Logo />
    </ButtonBase>
  );
};

export default LogoSection;
