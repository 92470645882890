import React from 'react';
import { Grid, Card, CardContent, CardMedia, Typography, Button, Link } from '@mui/material';
import canvaImgImage from "../../assets/images/products/canvaImg.jpg";

const GraphicCard = () => {
  const hostingData = [
    { name: 'Canva Pro', image: canvaImgImage, url: 'https://justpasteit.in/contact-us?item=canvapro', badge: 'Best WordPress Hosting', price: '₹299/Year' },
  ];

  return (
    <Grid container spacing={3}>
      {hostingData.map((hosting, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
          <Card>
            <div style={{ textAlign: 'center' }}>
              <CardMedia
                component="img"
                sx={{ width: 150, objectFit: 'cover', margin: 'auto' }}
                image={hosting.image}
                alt={`${hosting.name} Hosting`}
              />
            </div>
            <CardContent>
            <Typography variant="h4" color="textSecondary">
                {hosting.name}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Price: {hosting.price}
              </Typography>
            </CardContent>
            <Button variant="contained" color="secondary" fullWidth>
              <Link href={hosting.url} color="inherit" underline="none">
                Get Discount
              </Link>
            </Button>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default GraphicCard;