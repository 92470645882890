import * as React from 'react';
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import FileUpload from './FileUpload';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4
};
// material-ui
// import { useTheme } from '@mui/material/styles';

export default function FileUploadModal({ isOpen, toggleFileUploadModel, handleFileUpload, files }) {
//   const handleFileUpload = (uploadedFiles) => {
//     // Process the uploaded files here, e.g., send them to the server
//     handleFileUpload(uploadedFiles)
//     console.log('Uploaded Files from File upload model:', uploadedFiles);
//   };
  return (
    <div>
      <Modal open={isOpen} onClose={toggleFileUploadModel}>
        <Box sx={style}>
            <Typography variant="h3" m={1}>Upload Files</Typography>
            <FileUpload onFileUpload={handleFileUpload} files={files} toggleFileUploadModel={toggleFileUploadModel} />
        </Box>
      </Modal>
    </div>
  );
}
