import axios from 'axios';

const instance = axios.create({
  baseURL: '/api', // Replace with your API server URL
  timeout: 120000, // Adjust the timeout value as needed
});

// Optional: Add request interceptors
instance.interceptors.request.use(
  (config) => {
    // You can modify the request config here (e.g., add headers, authentication tokens, etc.)
    return config;
  },
  (error) => {
    // Handle request errors
    return Promise.reject(error);
  }
);

// Optional: Add response interceptors
instance.interceptors.response.use(
  (response) => {
    // You can modify the response data here (e.g., parse, transform, etc.)
    return response;
  },
  (error) => {
    // Handle response errors
    return Promise.reject(error);
  }
);

export default instance;