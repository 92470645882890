import React from "react";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Link,
  Rating,
} from "@mui/material";
import MousePadImage from "../../assets/images/products/Amazon/mouse_pad.jpg";
import LaptopStandImage from "../../assets/images/products/Amazon/laptop_stand.jpg";
import DellMouseImage from "../../assets/images/products/Amazon/dell_mouse.jpg";    
import CableProtectorImage from "../../assets/images/products/Amazon/spiral_charger.jpg";   

const AmazonCards = () => {
  const AmazonData = [
    {
      name: "Gaming Mouse Pad",
      image: MousePadImage,
      url: "https://amzn.to/3TqdhUu",
      Higest: "₹999",
      Lowest: "₹99",
      price: "₹99",
      rating: 4.4
    },
    {
        name: "Laptop Stand",
        image: LaptopStandImage,
        url: "https://amzn.to/3Pa2meZ",
        Higest: "₹1299",
        Lowest: "₹299",
        price: "₹299",
        rating: 4.3
      },
      {
        name: "Dell Mouse",
        image: DellMouseImage,
        url: "https://amzn.to/3V8cI2W",
        Higest: "₹500",
        Lowest: "₹269",
        price: "₹269",
        rating: 4.4
      },
      {
        name: "Charger Cable Protector",
        image: CableProtectorImage,
        url: "https://amzn.to/3P9oeHs",
        Higest: "₹99",
        Lowest: "₹99",
        price: "₹99",
        rating: 4.1
      },
  ];

  return (
    <Grid container spacing={3}>
      {AmazonData.map((Products, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
          <Card>
            <div style={{ textAlign: "center" }}>
              <CardMedia
                component="img"
                sx={{ width: 150, objectFit: "cover", margin: "auto" }}
                image={Products.image}
                alt={`${Products.name} Product`}
              />
            </div>
            <CardContent>
              <Typography variant="h4" color="textSecondary">
                {Products.name}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Higest Price: {Products.Higest}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Lowest Price: {Products.Lowest}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Price: {Products.price}
              </Typography>
              <Rating value={Products.rating} precision={0.1} readOnly />
            </CardContent>
            <Button variant="contained" color="secondary" fullWidth>
              <Link href={Products.url} color="inherit" underline="none">
                Get Discount
              </Link>
            </Button>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default AmazonCards;
