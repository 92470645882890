import React, { useState } from "react";
import {
  Container,
  Grid,
  Typography,
  Paper,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import MainCard from "../../components/cards/MainCard";
import tailwindcss from "../../assets/images/blog/Tailwindcss1.webp";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
const TailwindTutorial = () => {
  const [copyStatus, setCopyStatus] = useState("Copy Code");
  const codeBlock = `
  <main>
  <div class="flex justify-between mx-2 px-4 h-20 border-blue-400 shadow-sm items-center bg-blue-100">
    <div class="cursor-pointer">Logo</div>
    <div>
      <ul class="flex cursor-pointer">
        <li class="ml-2 hover:text-blue-600">Home</li>
        <li class="ml-2 hover:text-blue-600">About</li>
        <li class="ml-2 hover:text-blue-600">Contact</li>
      </ul>
    </div>
  </div>

  <div class="mx-2 px-4">
    <div class="block sm:flex border-red-400 gap-2 bg-blue-50 my-2">
      <div class="flex-1 bg-gray-600 p-10 text-center mx-auto mb-4 md:mb-0">
        <h3 class="font-bold py-2 text-sm text-left">Innovative Design</h3>
        <p class="text-sm text-left">This website has few innovative designs which are usefull for the business.</p>
        <Div class="text-left bg-blue-300 px-2 rounded-lg my-2 hover:bg-blue-600 cursor-pointer w-24">Shop Now</div>
      </div>
      <div class="flex-1 bg-gray-600 p-20 mb-4 md:mb-0">Image Section</div>
    </div>
    <div class="my-2">
      <h2 class="text-center font-semibold my-2">Top Category</h2>
      <div class="block sm:flex justify-between gap-2 my-2">
        <div class="bg-blue-100 p-20 w-full mb-4 md:mb-0 mx-auto text-center">Box 1</div>
        <div class="bg-blue-100 p-20 w-full mb-4 md:mb-0 mx-auto text-center">Box 2</div>
        <div class="bg-blue-100 p-20 w-full mb-4 md:mb-0 mx-auto text-center">Box 3</div>
      </div>
    </div>
    <div class="my-2">
      <h2 class="text-center font-semibold my-2">Popular products</h2>
      <div class="block sm:flex justify-between gap-2 my-2">
        <div class="bg-blue-100 p-10 mx-auto mb-4 md:mb-0 text-center">Box 1</div>
        <div class="bg-blue-100 p-10 mx-auto mb-4 md:mb-0 text-center">Box 2</div>
        <div class="bg-blue-100 p-10 mx-auto mb-4 md:mb-0 text-center">Box 3</div>
        <div class="bg-blue-100 p-10 mx-auto mb-4 md:mb-0 text-center">Box 4</div>
        <div class="bg-blue-100 p-10 mx-auto mb-4 md:mb-0 text-center">Box 5</div>
      </div>
    </div>
    <div class="my-2">
       <h2 class="text-center font-semibold my-2">Testimonials</h2>
      <div class="block sm:flex justify-between gap-2 my-2">
        <div class="bg-blue-100 p-20 w-full mb-4 md:mb-0 mx-auto text-center">Box 1</div>
        <div class="bg-blue-100 p-20 w-full mb-4 md:mb-0 mx-auto text-center">Box 2</div>
        <div class="bg-blue-100 p-20 w-full mb-4 md:mb-0 mx-auto text-center">Box 3</div>
      </div>
    </div>
    </div>
  </div>

  <div class="flex bg-blue-100 h-20 items-center justify-between mx-2 px-4">
    <div class="cursor-pointer">Logo</div>
    <div>
      <ul class="flex cursor-pointer">
        <li class="ml-2 hover:text-blue-600">Home</li>
        <li class="ml-2 hover:text-blue-600">About</li>
        <li class="ml-2 hover:text-blue-600">Contact</li>
      </ul>
    </div>
  </div>
</main>
  `;

  const handleCopyClick = () => {
    navigator.clipboard.writeText(codeBlock);
    setCopyStatus("Copied!");
  };

  return (
    <MainCard title="" border={false} elevation={1} content={true}>
      <Container maxWidth="md">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              Learn Tailwindcss with Responsive Website Example
            </Typography>
            <img
              src={tailwindcss}
              alt="Learn Tailwindcss"
              style={{ width: "100%", height: "auto", marginBottom: '10px' }}
            />

            <Typography variant="body1" paragraph>
              Tailwindcss is a utility-first CSS framework that enables you to
              build custom designs without writing extensive CSS code. In this
              tutorial, I'll guide you through creating a responsive website
              using Tailwindcss.
            </Typography>

            <Typography variant="body1" paragraph>
              You can watch the video version of this tutorial on{" "}
              <Tooltip title="Watch on YouTube" arrow>
                <IconButton
                  href="https://youtu.be/RHLGAbyJOGI?si=-8S9PfaZtkukuwR8"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <PlayCircleOutlineIcon color="error" fontSize="large" />
                </IconButton>
              </Tooltip>
              .
            </Typography>

            <Typography variant="h5" gutterBottom>
              What You Will Learn:
            </Typography>
            <Typography variant="body1" paragraph>
              By the end of this tutorial, you will learn how to:
            </Typography>
            <ul>
              <li>Set up a Tailwindcss project</li>
              <li>Use Tailwindcss classes to style HTML elements</li>
              <li>Utilize Tailwindcss breakpoints for a responsive design</li>
              <li>Add effects and animations using Tailwindcss utilities</li>
            </ul>
            <Typography variant="body1" paragraph>
              To follow this tutorial, you will need:
            </Typography>
            <ul>
              <li>A basic knowledge of HTML and CSS</li>
              <li>A code editor of your choice</li>
              <li>A browser to preview the website</li>
            </ul>
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ p: 2, mb: 2 }}>
              <Typography variant="h5">Code Block</Typography>
              <Paper
                sx={{
                  p: 1,
                  mb: 1,
                  backgroundColor: "#e0e0e0",
                  overflowX: "auto",
                  whiteSpace: "pre-wrap",
                  borderRadius: 2,
                }}
              >
                {codeBlock}
              </Paper>
              <Button
                variant="outlined"
                color="secondary"
                sx={{ mt: 2 }}
                onClick={handleCopyClick}
              >
                {copyStatus}
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </MainCard>
  );
};

export default TailwindTutorial;
