import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import instance from "../../Axios/axiosConfig";

const featureRows = [
    { feature: "Publish Text", guest: "NO", registered: "No", premium: "Yes" },
    { feature: "No ads for you and your readers", guest: "N/A", registered: "N/A", premium: "Yes" },
    { feature: "Captcha during adding notes", guest: "Yes", registered: "Rare", premium: "No" },
    { feature: "Notes management panel", guest: "No", registered: "Yes", premium: "Yes" },
    { feature: "Sharing notes with selected users", guest: "No", registered: "No", premium: "Yes" },
    { feature: "Voting on notes", guest: "No", registered: "No", premium: "Yes" },
    { feature: "Favourite notes", guest: "No", registered: "No", premium: "Yes" },
    { feature: "Password encrypted notes and images", guest: "No", registered: "No", premium: "Yes" },
    { feature: "Limit of images per note", guest: "40", registered: "40", premium: "100" },
    { feature: "Limit of images size per note (MB)", guest: "20", registered: "20", premium: "100" },
    { feature: "Premium support", guest: "No", registered: "No", premium: "Yes" },
    { feature: "Access to beta functionality", guest: "No", registered: "No", premium: "Yes" },
    { feature: "Support of JustPaste.it development", guest: "No", registered: "No", premium: "Yes" }
];

const CloseIcon = ({ onClick }) => (
  <svg
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    style={{ cursor: "pointer", position: "absolute", top: 16, right: 16 }}
  >
    <path
      fill="currentColor"
      d="M12 10.586l4.95-4.95 1.414 1.414L13.414 12l4.95 4.95-1.414 1.414L12 13.414l-4.95 4.95-1.414-1.414L10.586 12 5.636 7.05 7.05 5.636 12 10.586z"
    />
  </svg>
);

const PaymentButton = ({ open, handleClose }) => {
  const theme = useTheme();
  const [errorMessage, setErrorMessage] = useState(""); // Error message state for login

  // Modified handleClose function to reset error message when dialog closes
  const handleDialogClose = () => {
    setErrorMessage(""); // Clear error message
    handleClose(); // Call the original close handler
  };

  const handleBuyClick = async (planType) => {
    const email = localStorage.getItem("email");

    if (!email) {
      // Check if user is logged in via Google
      setErrorMessage("Please log in using Google to continue.");
      return;
    }

    try {
      const amount = planType === "+1 Month" ? 99 : 499; // Set amount based on plan

      // Call backend API to create an order
      const { data: { orderId, amount: backendAmount } } = await instance.post('/survey/create-order', {
        userId: email,
        plan: planType,
        amount,
      });

      // Initialize Razorpay payment
      const options = {
        key: 'rzp_live_m0fggFFKlBqbIy', // Replace with your Razorpay Key ID
        amount: backendAmount, // Convert amount to paise
        currency: 'INR',
        name: 'Just Paste it',
        description: `Purchase ${planType} plan`,
        order_id: orderId,
        handler: async (response) => {
          const paymentData = {
            paymentId: response.razorpay_payment_id,
            orderId: response.razorpay_order_id,
            signature: response.razorpay_signature,
            userId: email, // Pass the user email as the identifier
          };

          // Verify payment on your server
          const verifyResponse = await instance.post('/survey/verify-payment', paymentData);

          if (verifyResponse.data.success) {
            alert("Payment successful! You are now a premium user.");
            localStorage.setItem("isPremium", true);
          } else {
            alert("Payment verification failed. Please try again.");
            localStorage.setItem("isPremium", false);
          }
        },
        prefill: {
          email,
        },
      };

      const razorpayInstance = new window.Razorpay(options);
      razorpayInstance.open();
    } catch (error) {
      console.error("Payment failed", error);
      alert("An error occurred while processing the payment. Please try again.");
    }
  };

  return (
    <Dialog open={open} onClose={handleDialogClose} maxWidth="md" fullWidth>
      <CloseIcon onClick={handleDialogClose} />
      <Typography
        variant="h3"
        color="secondary"
        sx={{ textAlign: "center", p: 3 }}
      >
        Choose Your Premium Plan
      </Typography>
      <DialogContent>
        <Grid container spacing={3}>
          {/* 1 Month Plan */}
          <Grid item xs={12} sm={6}>
            <Paper
              elevation={5}
              sx={{
                padding: 3,
                textAlign: "center",
                borderRadius: 3,
                background: "linear-gradient(135deg, #f3ec78, #af4261)",
              }}
            >
              <Typography variant="h6" color="white" fontWeight="bold">
                +1 Month
              </Typography>
              <Typography variant="h4" color="white" fontWeight="bold">
                ₹99
              </Typography>
              <Button
                variant="contained"
                sx={{ mt: 2, backgroundColor: "#ffffff", color: "#af4261" }}
                onClick={() => handleBuyClick("+1 Month")} // OnClick handler for 1 Month plan
              >
                Buy Now
              </Button>
            </Paper>
          </Grid>
          {/* 1 Year Plan */}
          <Grid item xs={12} sm={6}>
            <Paper
              elevation={5}
              sx={{
                padding: 3,
                textAlign: "center",
                borderRadius: 3,
                background: "linear-gradient(135deg, #56ccf2, #2f80ed)",
              }}
            >
              <Typography variant="h6" color="white" fontWeight="bold">
                +1 Year
              </Typography>
              <Typography variant="h4" color="white" fontWeight="bold">
                ₹499
              </Typography>
              <Button
                variant="contained"
                sx={{ mt: 2, backgroundColor: "#ffffff", color: "#af4261" }}
                onClick={() => handleBuyClick("+1 Year")} // OnClick handler for 1 Year plan
              >
                Buy Now
              </Button>
            </Paper>
          </Grid>
        </Grid>

        {/* Error Message for Login */}
        {errorMessage && <p style={{ color: 'red', marginTop: '20px', textAlign: 'center' }}>{errorMessage}</p>}

        {/* Premium Features Table */}
        <TableContainer
          component={Paper}
          sx={{ marginTop: 4, borderRadius: 3 }}
        >
          <Table aria-label="premium features table">
            <TableHead sx={{ backgroundColor: theme.palette.secondary.main }}>
              <TableRow>
                <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                  Features
                </TableCell>
                <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                  Guest
                </TableCell>
                <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                  Registered
                </TableCell>
                <TableCell sx={{ color: "white", fontWeight: "bold" }}>
                  Premium
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {featureRows.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:nth-of-type(even)": {
                      backgroundColor: theme.palette.action.hover,
                    },
                  }}
                >
                  <TableCell>{row.feature}</TableCell>
                  <TableCell>{row.guest}</TableCell>
                  <TableCell>{row.registered}</TableCell>
                  <TableCell>{row.premium}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

export default PaymentButton;
