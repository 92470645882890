import React from "react";
import {
  Button,
  Typography,
  Grid,
} from "@mui/material";
import MainCard from "../components/cards/MainCard";
import { useTheme } from "@mui/material/styles";
import BetterResponseRow from "./CategoryRow";
import useMediaQuery from "@mui/material/useMediaQuery";
import CustomLinkModel from "./CustomLinkModel";
import { gridSpacing } from "../store/constant";

const LinkPage = () => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const theme = useTheme();
  const [getLinkModelOpen, setLinkModelOpen] = React.useState(false);

  const toggleLinkModel = () => {
    console.log("toggleLink fn");
    setLinkModelOpen((prev) => !prev);
  };
  return (
    <MainCard
      title=""
      border={false}
      elevation={1}
      content={true}
      boxShadow
      shadow={theme.shadows[16]}
    >
      <Grid
        container
        spacing={gridSpacing}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item>
          {/* <Typography variant="h2">Just Paste It</Typography> */}
          <Typography
            variant="h1"
            gutterBottom
            style={{ fontSize: "1rem", fontWeight: 500 }}
          >
            Just Paste It
          </Typography>
        </Grid>
        <Grid item>
          <Button
            disableElevation
            fullWidth
            size={isMobile ? "small" : "large"}
            type="submit"
            variant="contained"
            color="secondary"
            onClick={toggleLinkModel}
          >
            Custom Link
          </Button>
        </Grid>
      </Grid>
      <CustomLinkModel
        isOpen={getLinkModelOpen}
        toggleLinkModel={toggleLinkModel}
      />
      <BetterResponseRow />
    </MainCard>
  );
};

export default LinkPage;
